<template>
  <div class="layout-default flex flex-col">
    <TheHeader />
    <main class="content-wrap flex-1 px-xs">
      <slot />
    </main>
    <TheFooter />
    <TheBottomNavigation />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.layout-default {
  min-height: 100dvh;
  padding-bottom: $main-bottom-navigation-height;

  main {
    position: relative;
  }
}
</style>
